import { ColumnsMap } from '@/classes/ColumnsMap.js'

export class Import {
    id = null
    name = ''
    url = ''
    frequency = ''
    type = ''
    fileName = ''
    originalFilename = ''
    skipHeader = 0
    separator = ','
    fieldsMap = { }
    columnsMap = new ColumnsMap()
    groupsIds = []
    newGroups = []
    collisionAction = 2
    countryIso = ''


    setData (data) {
        this.name = data.name
        this.url = data.url
        this.frequency = data.frequency
        this.type = data.type
        this.fileName = data.fileName
        this.originalFilename = data.originalFilename
        this.skipHeader = data.skipHeader
        this.separator = data.separator
        this.fieldsMap = data.fieldsMap
        this.columnsMap.setData(data.columnsMap)
        this.groupsIds = data.groupsIds
        this.newGroups = data.newGroups
        this.collisionAction = data.collisionAction
        this.countryIso = data.countryIso
    }

    isManual () {
        return this.type === 'manual'
    }

    isSynchronized () {
        return this.type === 'synchronized' || this.type === 'klaviyo-synchronized'
    }

    isValid () {
        const hasEmail = this.columnsMap.email !== null
        const hasPhone = this.columnsMap.phone !== null || this.columnsMap.landline !== null
        const hasCountry = this.countryIso !== '' && this.countryIso !== null
        if (hasEmail) {
            if (hasPhone) {
                // eslint-disable-next-line max-depth
                if (hasCountry) {
                    return true
                }

                return false
            }

            return true
        } else if (hasPhone && hasCountry) {
            return true
        }

        return false
    }
}
