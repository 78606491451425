export class ColumnsMap {
    countryIso = null
    email = null
    phone = null
    landline = null
    createdAt = null
    externalId = null

    isValid () {
        if (this.email !== null || (this.phone !== null || this.landline !== null)) {
            return true
        }
        return false
    }

    setData (data) {
        this.countryIso = data.countryIso
        this.email = data.email
        this.phone = data.phone
        this.landline = data.landline
        this.createdAt = data.createdAt
        this.externalId = data.externalId
    }
}
